import logo from './logo.svg';
import './App.css';
import {useState} from "react";

function App() {

  const [response, setResponse] = useState(null);
  const handleClick = async () => {
    try {
      const apiResponse = await fetch('https://api.scottschulz.dev/getTest');
      const data = await apiResponse.json();
      setResponse(data);
    } catch (error) {
      console.error('Error fetching data:', error);
      setResponse({ error: 'Failed to fetch data' });
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo"/>
        <p>
          TEST: SUCCESS
        </p>
        <div>
          <button onClick={handleClick}>Call API</button>
          {response && (
              <div>
                <h2>Response:</h2>
                <pre>{JSON.stringify(response, null, 2)}</pre>
              </div>
          )}
        </div>
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
            className="App-link"
            href="https://reactjs.org"
            target="_blank"
            rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}

export default App;
